<template>
  <div class="single-item col-6 col-lg-4 photos">
    <a @click="showModal(index)" class="portfolio-item">
      <div class="portfolio-wrapper">
        <img :id="'image-'+imageContent.title" class="img-fluid"  :alt="imageContent.title" :src="imageContent.src">
        <div class="item-content">
          <h4 class="content-title">{{ imageContent.title }}</h4>
          <p class="content-description">{{ imageContent.description }}</p>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'PortfolioImage',
  components: {},
  props: {
    imageIndex:{
      type: Number,
      required: true
    },
    imageContent: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      index: this.imageIndex,
      info: this.imageContent,
    }
  },
  methods:{
    showModal(index){
      this.$emit('showImg',{
        index
      })
    }
  },
}
</script>

<style scoped>

</style>