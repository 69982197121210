<template>
  <div class="container">
    <div class="info-section single-section">
      <div class="services-section single-section">
        <div class="row">
          <div class="col-12 col-lg-5 info-img mb-5">
            <div class="d-flex flex-column">
              <div class="align-self-center profile-content">
                <img class="profile-image" :src="info.profileImage">
              </div>
              <div class="info-content">
                <div class="d-flex justify-content-center">
                  <span class="mr-3">Acompanhe-me</span>
                  <ul class="list-unstyled content-follow">
                    <li class="list-inline-item">
                      <a target="_blank" :href="info.instagram">
                        <i class="icon ion-logo-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-7 pt-2">
            <div class="section-heading">
              <p style="font-size: 24px" class="section-title">Formulário</p>
            </div>
            <div class="card text-white bg-dark">
              <div class="card-body">
                <quotation-form :artist-email="info.email"></quotation-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="services-section single-section">
      <div class="row">
        <div class="col-12">
          <div class="section-heading">
            <p class="section-description">Deem uma olhada no meu</p>
            <h2 class="section-title">Portfolio</h2>
          </div>
        </div>
      </div>
      <div class="portfolio-section single-section">
        <div class="portfolio-grid row">
          <template v-for="(item,key) in info.portfolioContent">
            <portfolio-image :image-index="key" :image-content="item" @showImg="showImg" :key="'key'+key"></portfolio-image>
          </template>
          <vue-easy-lightbox :imgs="info.portfolioContent" :index="index" :visible="visible" @hide="handleHide"></vue-easy-lightbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PortfolioImage from "@/components/Crew/PortfolioImage.vue";
import QuotationForm from "@/components/Quotation/QuotationForm.vue";
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'ArtistView',
  components: {
    QuotationForm,
    PortfolioImage,
    VueEasyLightbox
  },
  props: {
    artistData: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      visible: false,
      index: 0,
      info: this.artistData
    }
  },
  mounted() {
    this.$emit('swapTitle', {
      title: this.info.name,
      subtitle: this.info.subtitle
    })
  },
  methods: {
    showImg(value) {
      this.index = value.index
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  }
}
</script>

<style>
.vel-modal {
  background: rgba(0,0,0,0.95) !important;
}
</style>