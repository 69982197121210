<template>
  <nav class="navbar-expand-md navbar fixed-top" id="navbar">
    <!--logo-->
    <a class="navbar-brand" >
      <img class="img-fluid" :src="logo" alt="Logo">
    </a>
    <!--menu dropdown-->
    <span class="navbar-menu ml-auto" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" role="button">
            <span class="btn-line"></span>
        </span>
    <div class="collapse navbar-collapse order-1 order-lg-0" id="navbarSupportedContent">
      <!-- Navbar menu-->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item"><router-link class="nav-link" to="sobre">Sobre nós</router-link></li>
        <li class="nav-item"><router-link class="nav-link" to="equipe">Equipe</router-link></li>
        <li class="nav-item"><router-link class="nav-link" to="duvidas">FAQ/Dúvidas</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script>
import logoImage from '@/assets/img/logo.png';

export default {
  name: 'NavigationBar',
  data: function () {
    return {
      logo: logoImage
    }
  }

}
</script>

<style scoped>

</style>