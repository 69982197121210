import artistOneImg from "@/assets/img/artists/one/profile.jpg";
import artistTwoImg from "@/assets/img/artists/two/profile.jpg";

import firstOnePortfolio from "@/assets/img/artists/one/1.jpg"
import secondOnePortfolio from "@/assets/img/artists/one/2.jpg"
import thirdOnePortfolio from "@/assets/img/artists/one/3.jpg"
import fourthOnePortfolio from "@/assets/img/artists/one/4.jpg"
import fiftOnePortfolio from "@/assets/img/artists/one/5.jpg"
import sixtOnePortfolio from "@/assets/img/artists/one/6.jpg"

import firstTwoPortfolio from "@/assets/img/artists/two/1.jpg"
import secondTwoPortfolio from "@/assets/img/artists/two/2.jpg"
import thirdwoPortfolio from "@/assets/img/artists/two/3.jpg"
import fourthTwoPortfolio from "@/assets/img/artists/two/4.jpg"
import fiftTwoPortfolio from "@/assets/img/artists/two/5.jpg"
import sixtTwoPortfolio from "@/assets/img/artists/two/6.jpg"

const artistOneInfo = {
    name: "Helen Matos",
    description: "Apaixonada por Hora de Aventura e com uma lista de personagens que sonha em tatuar!",
    profileImage: artistOneImg,
    redirectLink: '/equipe/helen',
}

const artistTwoInfo = {
    name: "Rique Meraki",
    description: "Sou tatuador a 5 anos e Coofundador do Akatsuki Studio o primeiro estúdio de tatuagem otaku e geek da Bahia.",
    profileImage: artistTwoImg,
    redirectLink: '/equipe/rique'
}

const artistOneComplement = {
    subtitle: 'Apresentando um pouco sobre',
    instagram: 'https://www.instagram.com/helenmatosink/',
    email: 'helenmatos@tatuagemdeanime.com.br',
    portfolioContent: [
        {
            title: 'Sanji',
            description: 'One Piece',
            src: firstOnePortfolio
        }, {
            title: 'Zoro',
            description: 'One Piece',
            src: secondOnePortfolio
        }, {
            title: 'Itachi e Sasuke',
            description: 'Naruto Shippuden',
            src: thirdOnePortfolio
        }, {
            title: 'Luffy',
            description: 'One Piece',
            src: fourthOnePortfolio
        }, {
            title: 'Gojo',
            description: 'Jujutsu Kaisen',
            src: fiftOnePortfolio
        }, {
            title: 'Lucian',
            description: 'League Of Legends',
            src: sixtOnePortfolio
        }
    ]
}

const artistTwoComplement = {
    subtitle: "Eu me nomeio o mais BRABO",
    instagram: 'https://www.instagram.com/riquemeraki/',
    email: 'riquemeraki@tatuagemdeanime.com.br',
    portfolioContent: [
        {
            title: 'Shunsui Kyōraku',
            description: 'Bleach',
            src: firstTwoPortfolio
        }, {
            title: 'Kenpachi Zaraki',
            description: 'Bleach',
            src: secondTwoPortfolio
        }, {
            title: 'Itachi',
            description: 'Naruto',
            src: thirdwoPortfolio
        }, {
            title: 'Shisui',
            description: 'Naruto',
            src: fourthTwoPortfolio
        }, {
            title: 'Kakashi',
            description: 'Naruto',
            src: fiftTwoPortfolio
        }, {
            title: 'Chopper e Law',
            description: 'One Piece',
            src: sixtTwoPortfolio
        }
    ]
}
export const crewInfo = [
    artistOneInfo, artistTwoInfo
]

export const artistOne = {
    ...artistOneInfo,
    ...artistOneComplement
}
export const artistTwo = {
    ...artistTwoInfo,
    ...artistTwoComplement
}