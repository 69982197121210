import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from "@/views/AboutView.vue";
import CrewView from "@/views/CrewView.vue";
import QuestionsView from "@/views/QuestionsView.vue";
import ContentLayoutView from "@/views/Layout/ContentLayoutView.vue";
import GlobalLayoutView from "@/views/Layout/GlobalLayoutView.vue";
import ArtistView from "@/views/Crew/ArtistView.vue";
import {artistOne, artistTwo} from "@/data/crew";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter)

const routes = [
    {
        component: GlobalLayoutView,
        path: "",
        children: [
            {
                path: '/',
                name: 'home',
                meta: {
                    title: 'Akatsuki Studio'
                },
                component: HomeView
            },
            {
                component: ContentLayoutView,
                path: "",
                children: [
                    {
                        path: '/sobre',
                        name: 'about',
                        meta: {
                            title: 'Sobre - Akatsuki Studio'
                        },
                        component: AboutView
                    },
                    {
                        path: '/equipe',
                        name: 'crew',
                        meta: {
                            title: 'Equipe - Akatsuki Studio'
                        },
                        component: CrewView
                    },
                    {
                        path: '/equipe/helen',
                        name: 'crew-helen',
                        props: {
                            artistData: artistOne
                        },
                        meta: {
                            title: 'Helen - Akatsuki Studio'
                        },
                        component: ArtistView
                    },
                    {
                        path: '/equipe/rique',
                        name: 'crew-rique',
                        props: {
                            artistData: artistTwo
                        },
                        meta: {
                            title: 'Rique - Akatsuki Studio'
                        },
                        component: ArtistView
                    },
                    {
                        path: '/duvidas',
                        name: 'questions',
                        meta: {
                            title: 'Dúvidas - Akatsuki Studio'
                        },
                        component: QuestionsView
                    },
                ]
            },
        ],
    },

    {path: '*', component: NotFound}
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Akatsuki Studio';
    next();
});

export default router
