<template>
  <div class="container">
    <div class="d-flex justify-content-center pb-5">
      <span class="step"></span>
      <span class="step"></span>
      <span class="step"></span>
      <span class="step"></span>
    </div>
    <div class="tab">
      <div class="content-description text-center my-5">
        <p>Pronto para a próxima arte? Clique abaixo para dar
          inicio ao orçamento.
          <br>Lembre-se de que este formulário é exclusivo
          para <b>solicitações de orçamentos</b>.
          Caso tenha alguma dúvida que não esteja relacionada
          ao orçamento, por favor, visite nossa página de FAQ.
          <br>Obrigada por me escolher para sua próxima
          tatuagem
        </p>
      </div>
    </div>
    <div class="tab">
      <div class="row">
        <div class="col-12 col-md-6 form-group">
          <label>
            Nome Completo <span class="text-danger">*</span>
          </label>
          <input v-model="dataForm.name" placeholder="Ex. Ryomen Sukuna" class="form-control" type="text" name="name">
          <div class="invalid-feedback">
          </div>
        </div>

        <div class="col-12 col-md-6 form-group">
          <label>
            Data de nascimento <span class="text-danger">*</span>
          </label>
          <input v-model="dataForm.birthDate" @focusout="checkAge" class="form-control" type="date" name="birthDate">
          <div class="invalid-feedback">
          </div>
        </div>

        <div class="col-12 form-group">
          <label>
            Email <span class="text-danger">*</span>
          </label>
          <input v-model="dataForm.email" class="form-control" type="email" name="email"
                 placeholder="Ex. studio@studio.com">
          <div class="invalid-feedback">
            É necessário informar o email
          </div>
        </div>

        <div class="col-12 col-md-6 form-group">
          <label>Instagram</label>
          <input v-model="dataForm.instagram" class="form-control" type="text" name="instagram"
                 placeholder="Ex. @studidor">
        </div>

        <div class="col-12 col-md-6 form-group">
          <label>
            Telefone (Whatsapp) <span class="text-danger">*</span>
          </label>
          <input v-model="dataForm.phoneNumber" v-mask="'(##) #####-####'" class="form-control" type="text"
                 name="phoneNumber"
                 placeholder="Ex. (71)99999-9999">
          <div class="invalid-feedback"></div>
        </div>

        <div class="col-12 form-group form-check">
          <input name="ageConfirm" type="checkbox" value="true" class="form-check-input" id="ageConfirm">
          <label class="form-check-label" for="ageConfirm">Você confirma ser maior de 18 anos?</label>
          <div class="invalid-feedback"></div>
        </div>
      </div>
    </div>
    <div class="tab">
      <div class="row">
        <div class="col-12 col-md-4 form-group">
          <label>
            Cor da Tatuagem <span class="text-danger">*</span>
          </label>
          <select v-model="dataForm.colorTattoo" class="form-control" name="colorTattoo">
            <option value="" disabled selected>Selecionar</option>
            <option value="Preto">Preto</option>
            <option value="Colorido">Colorido</option>
          </select>
          <div class="invalid-feedback"></div>
        </div>

        <div class="col-12 col-md-4 form-group">
          <label>
            Tamanho da tatuagem <span class="text-danger">*</span>
          </label>
          <input v-model="dataForm.lengthTattoo" class="form-control" v-mask="'###'" name="lengthTattoo" required>
          <small id="ideaTattoHelper" class="form-text text-muted">
            O tamanho é em centimentros.
          </small>
          <div class="invalid-feedback"></div>
        </div>

        <div class="col-12 col-md-4 form-group">
          <label>
            Local da Tatuagem <span class="text-danger">*</span>
          </label>
          <select v-model="dataForm.bodyRegion" class="form-control" name="bodyRegion" required>
            <option value="" disabled selected> Selecionar</option>
            <option value="Peito">Peito</option>
            <option value="Costas">Costas</option>
            <option value="Braço">Braço</option>
            <option value="Antebraço">Antebraço</option>
            <option value="Mão">Mão</option>
            <option value="Pescoço">Pescoço</option>
            <option value="Coxa">Coxa</option>
            <option value="Panturilha">Panturilha</option>
          </select>
          <div class="invalid-feedback"></div>
        </div>

        <div class="col-12 form-group">
          <label>
            Descreva sua ideia da tatuagem <span class="text-danger">*</span>
          </label>
          <textarea required class="form-control" name="idea" v-model="dataForm.idea" rows="6"></textarea>
          <small id="ideaTattoHelper" class="form-text text-muted">
            Compartilhe sua ideia e criaremos algo único
            juntos! Por favor, entenda que trabalho apenas
            com arte original e não reproduzo tatuagens de
            outros artistas.
          </small>
          <div class="invalid-feedback"></div>
        </div>
      </div>
    </div>
    <div class="tab">
      <div class="row">
        <div class="col-12 form-group">
          <label for="referenceIdea">Imagens de referência para me ajudar a visualizar sua ideia</label>
          <input id="referenceIdea" ref="referenceIdea" name="referenceIdea" @change="uploadImage()" type="file"
                 multiple accept="image/*"
                 class="form-control-file">
          <small id="referenceHelper" class="form-text text-muted">
            Tem alguma referência? Anexe abaixo!
          </small>
          <div class="invalid-feedback"></div>
        </div>

        <div class="col-12 form-group">
          <label for="bodyReference">Fotos da área do seu corpo que será tatuada</label>
          <input id="bodyReference" ref="bodyReference" name="bodyReference" @change="uploadImage()" type="file"
                 multiple accept="image/*"
                 class="form-control-file">
          <small id="bodyReferenceHelper" class="form-text text-muted">
            Lembre-se que tem que ser sua.
          </small>
          <div class="invalid-feedback"></div>
        </div>
      </div>
    </div>
    <div class="align-content-end float-right">
      <button type="button" id="prevBtn" class="btn button-main" @click="nextPrev(-1)">
        Voltar
      </button>
      <button type="button" id="nextBtn" class="btn button-main button-scheme next-btn" @click="nextPrev(1)">
        Próximo
      </button>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import {post} from "axios";
import errorReportHelper from "@/mixins/errorReportHelper";

export default {
  name: "QuotationForm",
  props: {
    artistEmail: {
      type: String,
      required: true
    }
  },
  mixins: [
    errorReportHelper
  ],
  data: function () {
    return {
      currentTab: 0,
      loading: false,
      formData: new FormData(),
      dataForm: {
        professionalEmail: this.artistEmail,
        name: null,
        birthDate: null,
        email: null,
        instagram: null,
        phoneNumber: '',
        colorTattoo: null,
        lengthTattoo: '',
        bodyRegion: null,
        idea: null,
        referenceIdea: [],
        bodyReference: []
      }
    }
  },
  mounted() {
    this.showTab(this.currentTab)
  },
  methods: {
    showTab(index) {
      var x = document.getElementsByClassName("tab");

      x[index].style.display = "block";

      if (index === 0) {
        document.getElementById("prevBtn").style.display = "none";
      } else {
        document.getElementById("prevBtn").style.display = "inline";
      }

      if (index === (x.length - 1)) {
        document.getElementById("nextBtn").innerHTML = "Enviar";
      } else {
        document.getElementById("nextBtn").innerHTML = "Proximo";
      }

      this.fixStepIndicator(index)
    },

    nextPrev(n) {
      let x = document.getElementsByClassName("tab");

      if (n === 1 && !this.validateForm()) return false;

      x[this.currentTab].style.display = "none";

      this.currentTab = this.currentTab + n;

      if (this.currentTab >= x.length) {
        this.submitQuotation();
        return false;
      } else {
        this.showTab(this.currentTab);
      }
    },

    validateForm() {
      var x, y, i, valid = true;

      x = document.getElementsByClassName("tab");

      var inputFields = x[this.currentTab].getElementsByTagName("input")
      var selectFields = x[this.currentTab].getElementsByTagName("select")
      var textFields = x[this.currentTab].getElementsByTagName("textarea")

      y = [].concat(...inputFields, ...selectFields, ...textFields);

      for (i = 0; i < y.length; i++) {
        if (y[i].name === "instagram") continue;

        if (this.validationRules(y[i])) {
          valid = false;
          y[i].className += " is-invalid";
        } else {
          y[i].classList.remove('is-invalid')
        }
      }

      if (valid) {
        document.getElementsByClassName("step")[this.currentTab].className += " finish";
      }

      return valid;
    },

    fixStepIndicator(n) {
      var i, x = document.getElementsByClassName("step");

      for (i = 0; i < x.length; i++) {
        x[i].className = x[i].className.replace(" active", "");
      }

      x[n].className += " active";
    },

    validationRules(inputField) {
      var name = inputField.name;
      var invalidText, regex = null;
      var invalidStatus = false;
      var invalidFeedback = inputField.closest('.form-group').querySelector('.invalid-feedback');

      if (inputField.value === "") {
        invalidText = "O preenchimento deste campo é obrigatório.";

        if (invalidFeedback != null) {
          invalidFeedback.innerHTML = invalidText;
        }

        return invalidStatus = true;
      }

      switch (name) {
        case "name":
          regex = new RegExp(/^[a-zA-Z\s]*$/)

          if (!regex.test(inputField.value)) {
            invalidStatus = true;
            invalidText = "Há caracteres inválidos.";
          }

          break;
        case "birthDate":
          var birthDate = new Date(inputField.value)

          if ((new Date().getFullYear() - birthDate.getFullYear()) < 18) {
            invalidStatus = true;
            invalidText = " Você precisa ser maior de 18 anos para enviar esse formulário.";
          }

          break;
        case "email":
          regex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

          if (!regex.test(inputField.value)) {
            invalidStatus = true;
            invalidText = "Você precisa inserir um email válido.";
          }
          break;
        case "phoneNumber":
          regex = new RegExp(/^\(\d{2}\) \d{5}-\d{4}/);

          if (!regex.test(inputField.value)) {
            invalidStatus = true;
            invalidText = "Você precisa inserir um telefone válido!";
          }

          break;
        case "ageConfirm":
          if (!inputField.checked) {
            invalidStatus = true;
          }

          break;
      }

      if (invalidFeedback != null && invalidText != null) {
        invalidFeedback.innerHTML = invalidText;
      }

      return invalidStatus;
    },

    submitQuotation() {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        }
      };

      Object.entries(this.dataForm).forEach(([key, value]) => {
        if (!this.formData.has(key)) {
          this.formData.append(key, value);
        }
      });

      Swal.fire({
        title: 'Tem certeza?',
        text: " Antes de enviar o formulário, verifique se todas as\n" +
            "informações estão corretas. Não queremos perder\n" +
            "nenhum detalhe.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, está tudo correto!'
      })
          .then((result) => {
                if (result.isConfirmed) {

                  this.loading = true;

                  post('api/quotations', this.formData, config)
                      .then(() => {
                        Swal.fire({
                          icon: 'success',
                          text: 'Formulário enviado com sucesso.',
                          showConfirmButton: false,
                        })

                        this.loading = false;

                        this.finishForm()
                      })
                      .catch(error => {
                        this.throwError(error.response)
                        this.this.loading = false;
                        this.currentTab--;

                        this.showTab(this.currentTab)
                      })
                } else {

                  this.currentTab--;
                  this.showTab(this.currentTab)
                }
              }
          )
    },

    checkAge() {
      var value = new Date(this.dataForm.birthDate)

      if ((new Date().getFullYear() - value.getFullYear()) < 18) {
        document.querySelector(".next-btn").disabled = true
        Swal.fire({
          icon: 'warning',
          title: '+18',
          text: ' Este orçamento é restrito a somente pessoas acima de 18 anos.',
          showConfirmButton: false,
        })
      } else {
        document.querySelector(".next-btn").disabled = false
      }
    },

    uploadImage() {
      let nameField = event.target.name;
      let fileList = event.target.files;

      this.formData.delete(nameField);

      for (let i = 0; i < fileList.length; i++) {
        this.formData.append(nameField + '[]', fileList[i]);
      }
    },

    finishForm() {
      Object.entries(this.dataForm).forEach(([key]) => {
        this.dataForm[key] = null
      });

      this.currentTab = 0
      this.fixStepIndicator(this.currentTab)
      this.showTab(this.currentTab)
    }
  }
}
</script>

<style>
.tab {
  display: none;
}

.step {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

.step.finish {
  background-color: #bf2a3d;
}

.spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  margin: -40px 0 0 -40px;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #bf2a3d;
  border-bottom: 10px solid #bf2a3d;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>