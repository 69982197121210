import Swal from "sweetalert2";

export default {
    methods: {
        throwError(exceptionContent) {
            const {data, status} = exceptionContent;
            let errorContent = {
                icon: "error",
                title: "Erro inesperado",
                text: data.message,
                footer: `<small>[${data.application_code ?? 999999}] ${data.message}</small>`,
                showConfirmButton: false
            }

            switch (status) {
                case 404: {
                    errorContent.title = "Conteúdo não encontrado";
                    errorContent.text = "O conteúdo/recurso não foi encontrado.";
                    break
                }
                case 422: {
                    errorContent.icon = "warning";
                    errorContent.title = "Atenção";
                    errorContent.text = "Verifique os dados que foram enviados e tente novamente.";
                    break
                }
                default:
                    break;
            }

            Swal.fire(errorContent)
        }
    }
}