<template>
  <tiny-slider v-bind="sliderOptions">
    <template v-for="(item, index) in items">
      <div :key="index" class="slider-item">
        <div class="single-review swiper-slide">
          <div class="review-header d-flex justify-content-between">
            <div class="review-client">
              <div class="media">
                <div class="client-details">
                  <h6 class="client-name">{{ item.name }}</h6>
                  <span class="client-role">{{ item.username }}</span>
                </div>
              </div>
            </div>
            <i class="icon ion-md-quote review-icon"></i>
          </div>
          <p class="review-content" v-html="item.text"></p>
        </div>
      </div>
    </template>
  </tiny-slider>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider';

export default {
  name: 'TestimonialSlider',
  components: {
    'tiny-slider': VueTinySlider
  },
  props: {
    testimonials: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      items: this.testimonials,
      sliderOptions: {
        items: 1,
        gutter: 30,
        "responsive": {
          "0": {
            "items": 1,
            "gutter": 0
          },
          "768": {
            "items": 2,
            "gutter": 30
          }
        },
        preventScrollOnTouch: 'auto',
        slideBy: "page",
        mouseDrag: true,
        swipeAngle: false,
        speed: 400,
        controls: false,
        navPosition: 'bottom'
      }
    }
  },
}
</script>

<style lang="scss">

</style>