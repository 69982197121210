<template>
  <div class="lightbox-close">
    <div class="close-btn" @click="routeBack"><span class="btn-line"></span></div>
  </div>
</template>

<script>
export default {
  name: 'ButtonRouteBack',
  methods: {
    routeBack() {
      this.$router.back(-1)
    }
  }
}
</script>
