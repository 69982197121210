<template>
  <div class="container">
    <div class="resume-section single-section">
      <div class="row">
        <template v-for="(item, index) in crewInfo">
          <image-redirect :key="index" :artist-info="item"></image-redirect>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ImageRedirect from "@/components/Crew/ImageRedirect.vue";
import {crewInfo} from "@/data/crew";

export default {
  name: 'CrewView',
  components: {
    ImageRedirect,
  },
  mounted() {
    this.$emit('swapTitle', this.content)
  },
  data: function () {
    return {
      crewInfo: crewInfo,
      content: {
        subtitle: 'Clique em uma das fotos, para uma experiência completa sobre os integrantes da...',
        title: 'Equipe'
      }
    }
  },

}
</script>

<style>

</style>