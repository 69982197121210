<template>
  <div class="home" id="content">
    <navigation-bar></navigation-bar>
    <div class="home-area element-cover-bg" id="home">
      <div class="container h-100">
        <div class="row h-100 align-items-center justify-content-center">
          <text-logo></text-logo>
        </div>
      </div>
      <FooterCustom></FooterCustom>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/Home/NavigationBar.vue";
import TextLogo from "@/components/Home/TextLogo.vue";
import FooterCustom from "@/components/Home/FooterCustom.vue";

export default {
  name: 'HomeView',
  components: {
    FooterCustom,
    TextLogo,
    NavigationBar,
  },
  data: function () {
    return {
    }
  },
}
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

</style>
