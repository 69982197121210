<template>
  <div class="container">
    <div class="info-section single-section">
      <div class="row align-items-center">
        <!-- Picture part-->
        <div class="col-12 info-content">
          <div class="content-block">
            <h2 class="content-subtitle">Quem somos?</h2>
            <div class="content-description text-justify">
              <p>Seja bem-vindo ao
                <b>Akatsuki Studio</b>, nosso estúdio de tatuagem que tem a cultura geek
                e anime como sua essência.<br>
                Nosso objetivo é
                <b>criar tatuagens únicas e personalizadas</b> que celebram suas paixões
                de maneira autêntica. Nós amamos discutir ideias e colaborar com você
                para criar algo incrível, com personalidade e original. Se você é um
                nerd, otaku, geek ou somente um um amante orgulhoso da cultura pop venha
                conhecer nosso estúdio.
                <b>Aqui nós estamos prontos para criar algo que celebra sua paixão de maneira autêntica</b>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="testimonials-section single-section">
      <div class="row">
        <div class="col-12">
          <div class="section-heading">
            <h3 class="content-subtitle">O que dizem sobre nós</h3>
          </div>
        </div>
        <div class="col-12">
          <testimonial-slider :testimonials="testimonials"></testimonial-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TestimonialSlider from "@/components/About/TestimonialSlider.vue";

export default {
  name: 'AboutView',
  components: {
    TestimonialSlider
  },
  mounted() {
    this.$emit('swapTitle', this.content)
  },
  data: function () {
    return {
      content: {
        subtitle: 'Conheçam mais...',
        title: 'Sobre nós'
      },
      testimonials: [
        {
          name: "Yasmim Melo",
          username: "@yasmimmeloo",
          text: "coisa mais linda!!!!!! <br> \n" +
              "precisando urgentemente voltar para lançar uma nova... obrigada por tudo,\n" +
              "helen!!!!! você é foda 🤍"
        }, {
          name: "Vitor",
          username: "@vitormxnuel",
          text: "Eu que agradeço por esse trampo incrível que tu fez\n" +
              "em mim, você é a hashira da tinta ❤️ em breve tem mais... "
        }, {
          name: "Anne",
          username: "@annedlira",
          text: "Te amo kuraminha, te amo helennnn logo mais\n" +
              "planossssss [cara malévola"
        }, {
          name: "Iel Gusmão",
          username: "@ielgusmao",
          text: " Rapaz, toda vez que eu olho eu penso: bonita pa caralho... nuca me canso!\n" +
              "Obrigada você por ter feito essa beleza toda (e as outra também rsrsrs) em mim! ❤️"
        }, {
          name: "Eric Yves",
          username: "@eric_yves",
          text: "Eu q agradeço irmão, pelo dia e pelo seu trabalho incrivel! Em breve teremos mais, grande abraço ❤️\n"
        }, {
          name: "Pedro Presidio",
          username: "@ppresidio",
          text: "apenas o melhor de todos!! Muito feliz de fazer presença na sua caminhada e sei\n"+
             "que a cada tattoo que fizer contigo, vai tar melhor (mesmo eu não conseguindo crer que melhora mais!)"
        },{
          name: "Yasmim Melo",
          username: "@yasmimmeloo",
          text: "você é foda"
        },{
          name: "Thiago",
          username: "@thiagotb37",
          text: "Foi um prazer fazer essa quarta tatuagem com você, Inhame sukehiro ficou fodastico dms"
        },
      ],
    }
  },
}
</script>

<style>

</style>