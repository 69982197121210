<template>
  <div class="d-flex flex-column fixed-bottom background-opacity">
    <div class="mx-auto divisor">
        <span class="info-content">All rights reserved © {{year}}</span>
    </div>
    <div class="row justify-content-center">
        <span class="info-content">Development by
          <a target="_blank" href="https://www.linkedin.com/in/ariel-rocha-nogueira/">ARN Solutions</a>
        </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterCustom',
  data: function () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>

</style>