<template>
  <div class="container">
    <div class="contact-section single-section">
      <div class="row">
        <div class="col-12 faq-section">
          <AccordionItems></AccordionItems>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccordionItems from "@/components/Questions/AccordionItems.vue";
export default {
  name: 'QuestionsView',
  components: {AccordionItems},
  mounted() {
    this.$emit('swapTitle', this.content)
  },
  data: function (){
    return {
      content: {
        subtitle: 'Em caso de dúvidas, temos o...',
        title: 'FAQ'
      }
    }
  }
}
</script>

<style>

</style>