<template>
  <div class="col-12 col-lg-12 home-content text-center">
    <h1 class="home-name">Akatsuki Studio</h1>
    <div class="d-flex justify-content-center">
      <VueTypedJs :strings="words" :loop="true" :typeSpeed="60" :backSpeed="30" :contentType="'html'" :cursorChar="'|'">
        <h2>
           <span class="typing"></span>
        </h2>
      </VueTypedJs>
    </div>
    <div class="my-5">
      <router-link class="btn button-main button-scheme btn-redirect" to="equipe">Conheça a Equipe...</router-link>
    </div>
  </div>
</template>

<script>
import {VueTypedJs} from 'vue-typed-js'

export default {
  name: 'TextLogo',
  components: {
    VueTypedJs
  },
  data: function () {
    return {
      words: [
        "<span class='font-anime'>Tatuagens de Animes</span>",
        "<span class='font-geek'>Tatuagens Geeks</span>"
      ]
    }
  }
}
</script>

<style>

</style>