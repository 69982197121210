<template>
  <div class="d-flex justify-content-center align-items-center" id="main">
    <span class="mr-3 pr-3 align-top border-right inline-block align-content-center">
      <img :src="logo" class="logo-image" alt="Logo">
    </span>
    <div class="inline-block align-self-center">
      <h2 class="font-weight-normal lead my-1">Ops, acho que tem algo de errado aqui...</h2>
    </div>
  </div>
</template>

<script>
import logoImage from "@/assets/img/logo.png";

export default {
  name: 'NotFound',
  data: function () {
    return {
      logo: logoImage
    }
  }
}
</script>

<style scoped>
#main {
  height: 100vh !important;
}

.logo-image {
  width: 8rem
}
</style>