<template>
  <div class="lightbox-wrapper w-100 h-100">
    <button-route-back></button-route-back>
    <div class="lightbox-content">
      <SimpleBar class="simplebar-mine">
        <div class="justify-content-center">
          <div class="col-12">
            <div class="section-heading page-heading">
              <p class="section-description">{{ subtitle }}</p>
              <h2 class="section-title">{{ title }}</h2>
              <div class="animated-bar"></div>
            </div>
          </div>
          <div class="col-12 content-info">
            <transition name="fade-slide" mode="out-in">
              <router-view @swapTitle="swapTitle">
              </router-view>
            </transition>
          </div>
        </div>
      </SimpleBar>
    </div>
  </div>
</template>

<script>
import ButtonRouteBack from "@/components/Layout/ButtonRouteBack.vue";
import SimpleBar from "simplebar-vue";
import 'simplebar/dist/simplebar.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default {
  name: 'ContentLayoutView',
  components: {
    SimpleBar,
    ButtonRouteBack
  },
  data: function () {
    return {
      title: "Titulo",
      subtitle: "Subtitulo"
    }
  },
  methods: {
    swapTitle(content) {
      this.title = content.title;
      this.subtitle = content.subtitle
    }
  }
}
</script>

<style>
.simplebar-mine {
  max-height: 868px
}

.content-info {
  margin-bottom: 8.5rem !important;
}
</style>