<template>
  <div>
    <div v-if="isLoading" class="preloader">
      <div class="preloader-block">
        <div class="preloader-icon">
          <span class="loading-dot loading-dot-1"></span>
          <span class="loading-dot loading-dot-2"></span>
          <span class="loading-dot loading-dot-3"></span>
        </div>
      </div>
    </div>
    <transition name="fade-slide" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import '@/assets/css/colors/main-darkred.css'
import '@/assets/css/bootstrap-custom.css'
import '@/assets/css/main.css'
import '@/assets/css/custom.css'
import '@/assets/css/icons.css'

export default {
  name: 'GlobalLayoutView',
  data: function () {
    return {
      isLoading: false
    }
  },
  created() {
    this.showPreloader()
  },
  methods: {
    showPreloader() {
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
      }, 1500); // Neste exemplo, vamos escondê-lo após 3 segundos
    }
  }
}
</script>

<style>

</style>