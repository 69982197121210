<template>
  <div class="accordion" :id="accordionName">
    <template v-for="(item,index) in questions">
      <div class="mb-3" :key="index">
        <div class="card bg-dark">
          <div class="card-header d-flex justify-content-between align-items-center" data-toggle="collapse"
               :data-target="'#collapse'+index" aria-expanded="false">
            <span>{{ item.question }}</span>
            <span class="action">
          <i class="fas fa-angle-down rotate-icon"></i>
        </span>
          </div>
          <div :id="'collapse'+index" class="collapse" :data-parent="'#'+accordionName">
            <div class="card-body">
              {{ item.response }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <!--    <div class="mb-3">
          <div class="card bg-dark">
            <div class="card-header collapsed d-flex justify-content-between align-items-center"
                 data-toggle="collapse" data-target="#collapseFive"
                 aria-expanded="true">
              <span>Quer esclarecer alguma dúvida ou solicitar mais informações? Entre em contato pelo instagram. </span>
              <span class="action">
                <i class="fas fa-angle-down rotate-icon"></i>
              </span>
            </div>
            <div id="collapseOne" class="collapse show" :data-parent="accordionName">
              <div class="card-body">
                {{ content.response }}
              </div>
            </div>
          </div>
        </div>-->
  </div>

</template>

<script>
export default {
  name: 'AccordionItems',
  data: function () {
    return {
      accordionName: 'accordionQuestions',
      questions: [
        {
          question: "Preciso ter idade mínima para tatuar com vocês?",
          response: "Sim, é necessário ter a idade mínima de 18 anos para realizar uma tatuagem conosco."
        }, {
          question: "Vocês atendem sem agendamento?",
          response: " Aqui no Akatsuki nós trabalhamos somente com horário marcado\n" +
              "para garantir que possamos dedicar todo o nosso tempo e atenção\n" +
              "a cada projeto individualmente e seguir um cronograma que atenda\n" +
              "às necessidades de nossos clientes. Dessa forma, podemos\n" +
              "garantir a qualidade de nossos serviços e a satisfação de nossos\n" +
              "clientes."
        }, {
          question: "Onde fica o Akatsuki?",
          response: "O endereço exato é uma surpresa que só revelamos após a\n" +
              "confirmação da sessão, para garantir a segurança de todos. Mas\n" +
              "podemos adiantar que estamos na cidade de Salvador!"
        }, {
          question: "Vocês fazem cópias de tatuagem?",
          response: " Aqui nós valorizamos a originalidade de cada tatuagem e\n" +
              "acreditamos que cada trabalho deve ser único e pessoal. Por\n" +
              "isso, não fazemos cópias."
        }
      ]
    }
  },
  methods: {}
}
</script>
