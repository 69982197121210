<template>
  <div class="col-12 col-md-6 p-2">
    <div class="col-block ink-one">
      <div class="col-12 col-lg-12 squad-img">
        <div class="content">
          <router-link :to="{path:info.redirectLink, props: {artistData: info}}" class="btn-redirect">
            <div class="content-overlay"></div>
            <img class="content-image" v-lazy-image="info.profileImage">
            <div class="content-details fadeIn-bottom">
              <h3 class="content-title">{{ info.name }}</h3>
              <p class="content-text">{{ info.description }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageRedirect',
  components: {},
  props: {
    artistInfo: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      info: this.artistInfo,
    }
  },
  directives: {
    'lazy-image': {
      inserted: function (el, binding) {
        el.style.filter = 'grayscale(100%)';

        const img = new Image();
        img.src = binding.value;

        img.onload = () => {
          el.style.filter = 'none';
          el.src = binding.value;
        };
      }
    }
  },
  methods: {
    redirectFromArtistRoutes(artist) {
      this.$router.push(
          {
            path: artist.redirectLink,
            params: {
              artistData: artist
            }
          }
      );
    }
  }
}
</script>

<style lang="scss">
@import 'tiny-slider/src/tiny-slider';

</style>